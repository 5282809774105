import React from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import ActionBar from "../components/OldActionBar";
import { Button } from "grommet/es6";
import { navigate } from "gatsby-link";
import Link from "../components/Link";
import PageWrapper from "../components/PageWrapper";
import AnalyticsIcon from "../components/icons/AnalyticsIcon";
import DarkPageHeading from "../components/DarkPageHeading";

const ALL_QUIZZES = gql`
  query {
    allQuizzes {
      id
      name
      publicId
      started
    }
  }
`;

const QuizListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const QuizListingWrapper = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

const AnalyticsLinkWrapper = styled.span`
  margin-left: 5px;
`;

export default function QuizList() {
  const { data } = useQuery(ALL_QUIZZES);

  const allQuizzes = data && data.allQuizzes;

  const handleCreateQuiz = () => {
    navigate("/create-edit-quiz");
  };

  return (
    <PageWrapper>
      <DarkPageHeading>Your Quizzes</DarkPageHeading>
      <QuizListContainer>
        {allQuizzes &&
          allQuizzes.map((quiz) => (
            <div key={quiz.id}>
              <QuizListingWrapper>
                <Link to={`/create-edit-quiz?quizId=${quiz.id}`}>
                  {quiz.name}
                </Link>
                {quiz.started && (
                  <AnalyticsLinkWrapper>
                    <Link to={`/analytics?quizId=${quiz.id}`}>
                      <AnalyticsIcon size="small" />
                    </Link>
                  </AnalyticsLinkWrapper>
                )}
              </QuizListingWrapper>
            </div>
          ))}
      </QuizListContainer>
      <ActionBar>
        <Button size="large" label="Create a Quiz" onClick={handleCreateQuiz} />
      </ActionBar>
    </PageWrapper>
  );
}
